const isProduction = /partner\.medikura\.com/.test(window.location.host)
const widgetUrl =
  process.env.WIDGET_URL || isProduction
    ? 'https://www.nebenwirkungen.de/widget/v3/'
    : 'https://capture.s3.medsvr.net'
export default {
  widgetUrl: widgetUrl,
  widgetPreviewUrl: 'https://capture.s3.medsvr.net',
  eventstoreUrl:
    process.env.EVENTSTORE_URL || isProduction
      ? 'https://eventstore.api.p3.production.medikura.com'
      : 'https://reports.api.s3.medsvr.net'
}``
