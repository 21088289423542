import React, { Component } from 'react'
import {
  Typography,
  Grid,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  StepButton
} from '@material-ui/core'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-monokai'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import uuid from 'uuid'
import { Buffer } from 'buffer'
import config from './config'

import rq from 'superagent'

import reportSample from './samples/report.example.json'
import paletteSample from './samples/paletteSample.example.json'

export default class App extends Component {
  constructor (props) {
    super(props)
    console.info(`Pipeline id: ${process.env.REACT_APP_CI_PIPELINE_ID}`)
    this.state = {
      tab: 'html',
      tab2: 'preview',
      tab3: 'widget',
      tabWidgetMode: 'blank',
      appId: uuid.v4(),
      sourceDomain: '',
      reportData: JSON.stringify(reportSample, null, 4),
      paletteData: JSON.stringify(paletteSample, null, 4),
      activeStep: 'integrate'
    }
  }

  componentWillMount () {
    this.loadSchema()
  }

  async loadSchema () {
    const url = `${config.eventstoreUrl}/schema/adverse-side-effect-report/3.0.0`
    const schemaResponse = rq.get(url)
    this.setState({ schema: schemaResponse.body })
  }

  handleValidate = data => {
    try {
      JSON.parse(data)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  handleStep (index) {
    this.setState({ activeStep: index })
  }

  render () {
    // if(!this.state.schema) return null

    const { tab, tab2, tab3, tabWidgetMode, activeStep } = this.state
    const externalParameters = `?appId=${encodeURIComponent(
      this.state.appId
    )}&sourceDomain=${encodeURIComponent(this.state.sourceDomain)}`
    const previewParameters = `?appId=6eacddd1-6de3-450d-ad37-24c130f273ad&sourceDomain=${window.location.host}`

    let htmlParameters = ''
    let jsParameters = ''
    let jsVars = ''

    if (tabWidgetMode === 'color') {
      htmlParameters += `&palette=${encodeURIComponent(
        Buffer.from(
          JSON.stringify(JSON.parse(this.state.paletteData))
        ).toString('base64')
      )}`
      jsParameters += `&palette='+encodeURIComponent(JSON.stringify(paletteData)) + '`
      jsVars += `var paletteData = ${this.state.paletteData}`
    } else if (tabWidgetMode === 'prefilled') {
      htmlParameters += `&report=${encodeURIComponent(
        JSON.stringify(JSON.parse(this.state.reportData))
      )}&palette=${encodeURIComponent(
        Buffer.from(
          JSON.stringify(JSON.parse(this.state.paletteData))
        ).toString('base64')
      )}`
      jsParameters += `&report='+encodeURIComponent(JSON.stringify(reportData))+'&palette='+encodeURIComponent(JSON.stringify(paletteData)) + '`
      jsVars += `var reportData = ${this.state.reportData}\n`
      jsVars += `  var paletteData = ${this.state.paletteData}\n`
    }

    const iframeAttributes = `style="width:100%;height:1000px;" scrolling="no" frameBorder="0"`
    const code = {
      htmlPreview: `<iframe src="${config.widgetPreviewUrl}${previewParameters}${htmlParameters}" ${iframeAttributes}></iframe>`,
      html: `<a href="${config.widgetUrl}${externalParameters}${htmlParameters}" ${iframeAttributes}>Your link here</a>`,
      javascript: `
  <script type="application/javascript">
  ${jsVars}
  document.write('<a href="${config.widgetUrl}${externalParameters}${jsParameters}" ${iframeAttributes}>Your link here</a>')
  </script>
  `
    }

    const tabWidgetModeToActiveStep = {
      blank: 'integrate',
      color: 'color',
      prefilled: 'report'
    }

    return (
      <div className='App'>
        <AppBar position='static'>
          <Toolbar>
            <Typography variant='title' color='inherit' style={{ flexGrow: 1 }}>
              Medikura Partner Portal Start
            </Typography>
            <Tabs value={tab3} onChange={(e, tab3) => this.setState({ tab3 })}>
              <Tab label='ADR Widget Integration' value='widget' />
              <Tab label='ADR API' value='api' />
            </Tabs>
          </Toolbar>
        </AppBar>
        <AppBar />
        {tab3 === 'widget' && (
          <div style={{ padding: 16, overflowX: 'hidden' }}>
            <Grid container className='App-modeler' spacing={16}>
              <Grid item xs={5}>
                <Paper>
                  <Tabs
                    value={tabWidgetMode}
                    onChange={(e, tabWidgetMode) =>
                      this.setState({
                        tabWidgetMode,
                        activeStep: tabWidgetModeToActiveStep[tabWidgetMode]
                      })
                    }
                  >
                    <Tab
                      label='Quickstart'
                      value='blank'
                      style={{ textTransform: 'inherit' }}
                    />
                    <Tab
                      label='With Custom Palette'
                      value='color'
                      style={{ textTransform: 'inherit' }}
                    />
                    {false && (
                      <Tab
                        label='Add Report Data'
                        value='prefilled'
                        style={{ textTransform: 'inherit' }}
                      />
                    )}
                  </Tabs>
                  <Stepper orientation='vertical' nonLinear>
                    {(tabWidgetMode === 'color' ||
                      tabWidgetMode === 'prefilled') && (
                      <Step active={activeStep === 'color'}>
                        {' '}
                        <StepButton onClick={_ => this.handleStep('color')}>
                          Configure Layout Information.
                        </StepButton>
                        <StepContent>
                          <Typography style={{ padding: '15px' }}>
                            We use material-ui themes to adjust the look and
                            feel of the widget. Most important is the{' '}
                            <b>palette.primary.main color</b>. See the{' '}
                            <a href='https://v1-4-0.material-ui.com/customization/themes/'>
                              Material UI documentation
                            </a>{' '}
                            for details on the options.
                            <br /> You can change the values below, while
                            checking the preview on the right. Best to remove
                            all parameters you don't want to overwrite.
                            <br />
                            When you are done, proceed to the next step and
                            integrate the snippet.
                          </Typography>

                          <AceEditor
                            mode='json'
                            theme='monokai'
                            value={this.state.paletteData}
                            onChange={newValue => {
                              if (this.handleValidate(newValue)) {
                                this.setState({ paletteData: newValue })
                              }
                            }}
                            name='paletteData'
                            style={{ width: '100%', height: '300px' }}
                          />
                        </StepContent>{' '}
                      </Step>
                    )}
                    {tabWidgetMode === 'prefilled' && (
                      <Step active={activeStep === 'report'}>
                        <StepButton onClick={_ => this.handleStep('report')}>
                          Define example report (optional)
                        </StepButton>
                        <StepContent>
                          <Typography style={{ padding: '15px' }}>
                            Use the <b>report</b> parameter to prefill the
                            report. You can use the below json to see the impact
                            of different prefilled values onto the widget on the
                            right. Also check our API documentation on the top
                            right for an explanation of these values.
                          </Typography>
                          <AceEditor
                            mode='json'
                            theme='monokai'
                            value={this.state.reportData}
                            onChange={newValue =>
                              this.setState({ reportData: newValue })
                            }
                            name='reportData'
                            style={{ width: '100%' }}
                          />
                        </StepContent>
                      </Step>
                    )}

                    <Step active={activeStep === 'integrate'}>
                      <StepButton onClick={_ => this.handleStep('integrate')}>
                        Integrate the widget into your website
                      </StepButton>
                      <StepContent>
                        <Typography style={{ padding: '15px' }}>
                          To integrate the widget into your website, just copy
                          the snippet below and paste it into your source code.
                          There are 3 parameters that can be set: <br />
                          <b>sourceDomain</b> - the domain where this widget
                          will be shown, e.g. <code>nebenwirkungen.de</code>.
                          This is used to identify where the reports came from.
                          <br />
                          <b>palette</b> - holding the url-encoded palette
                          definition in a json format. <br />
                        </Typography>

                        <div className='form-inline form-group'>
                          <label htmlFor='sourceDomain' className='mr-2'>
                            Domain:
                          </label>
                          <input
                            type='text'
                            className='form-control flex-fill'
                            value={this.state.sourceDomain}
                            name='sourceDomain'
                            id='sourceDomain'
                            placeholder='Please enter your domain'
                            onChange={e =>
                              this.setState({ sourceDomain: e.target.value })
                            }
                          />
                        </div>

                        <AppBar position='static' color='default'>
                          <Tabs
                            value={tab}
                            onChange={(e, tab) => this.setState({ tab })}
                          >
                            <Tab label='HTML' value='html' />
                            <Tab label='Javascript' value='javascript' />
                          </Tabs>
                        </AppBar>

                        <AceEditor
                          mode='html'
                          theme='monokai'
                          value={code[tab]}
                          name='integrate'
                          style={{
                            height: '300px',
                            width: '100%'
                          }}
                          readOnly
                          wrapEnabled
                        />
                      </StepContent>
                    </Step>
                  </Stepper>
                </Paper>
              </Grid>
              <Grid item xs={7}>
                <Paper>
                  <AppBar position='static' color='default'>
                    <Tabs
                      value={tab2}
                      onChange={(e, tab2) => this.setState({ tab2 })}
                    >
                      <Tab label='Preview' value='preview' />
                    </Tabs>
                  </AppBar>

                  {code && (
                    <>
                      {tab2 === 'preview' && (
                        <div
                          style={{ padding: 25 }}
                          dangerouslySetInnerHTML={{ __html: code.htmlPreview }}
                        />
                      )}
                      {tab2 === 'integration' && (
                        <Paper style={{ margin: 25 }} />
                      )}
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {tab3 === 'api' && (
          <iframe
            title='Iframe'
            src={`${config.eventstoreUrl}/docs/`}
            style={{
              width: '100%',
              height: `${parseInt(window.innerHeight) - 71}px`
            }}
            scrolling='yes'
            frameBorder='0'
          />
        )}
      </div>
    )
  }
}
